
import { defineComponent, onMounted, ref } from 'vue';
import { DungeonService } from '@/services';
import { Dungeon } from '@/models';
import { DungeonRoute } from '@/router';

export default defineComponent({
	name: 'Home',
	setup() {
		const dungeons = ref<ReadonlyArray<Dungeon>>([]);

		onMounted(async () => {
			await DungeonService.loadDefaults();
			dungeons.value = DungeonService.getAll();
		});

		return { dungeons, DungeonRoute };
	}
});
